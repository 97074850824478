<template>
  <div>
    <v-container class="container px-0">
      <v-row
        class="title-row"
        :class="`${
          $vuetify.breakpoint.mdAndDown ? 'flex-column flex-nowrap' : ''
        }`"
      >
        <v-col class="d-flex align-center px-3">
          <h4 class="font-weight-light">{{ $t('heading.hosting.emailForwarders.title') }}</h4>
          <active-server-account-selector
            v-if="service && service.email_server_internal && service.server_accounts.length > 1"
            class="ml-4"
            :value.sync="serverAccount"
            :items="service.server_accounts"
          />
        </v-col>

        <v-col
          class="page-title__filter-controls justify-end"
          :class="[`${$vuetify.breakpoint.mobile ? 'pb-3' : ''}`]"
        >
          <template v-if="data[0].items.length">
            <data-iterator-filter-controls
              :keys="data[0].headers"
              :showSearchTerm="true"
              :searchTerm="searchTerm"
              :fullWidthSearch="false"
              :fullWidthLg="false"
              :fullWidthMd="true"
              @update:sortBy="changeSortBy"
              @update:sortDesc="handleSortDescChange"
              @update:searchTerm="handleSearchTermChange"
            />

            <v-btn
              :large="!$vuetify.breakpoint.smAndDown"
              elevation="0"
              color="primary"
              class="p-2 add-new-button page-title__add-button"
              @click="loadAddForwarderModal"
              :loading="data[0].addButtonLoading"
            >
              <v-icon :class="$vuetify.breakpoint.mobile ? 'mr-0' : ''"
                >$plus</v-icon
              >
              {{ $vuetify.breakpoint.mobile ? "" : $t('button.emailForwarder.add') }}
            </v-btn>
          </template>
        </v-col>
      </v-row>
    </v-container>

    <v-container class="mt-3 px-0">
      <v-row>
        <v-col cols="12">
          <div :class="checkedItems.length > 0 ? 'pb-15' : ''">
            <hosting-table
              :headers="data[0].headers"
              :items="data[0].items"
              :itemsLoading="data[0].loading"
              :itemsPerPage="itemsPerPage"
              :itemsTotal="itemsTotal"
              :page="page"
              :possibleItemsPerPage="[[8, 16, 24]]"
              :searchTerm="searchTerm"
              :sortBy="sortBy"
              :sortDesc="sortDesc"
              @clearFilters="searchTerm = ''"
              @update:check-all="handleSelectAll"
              @update:checked-items="handleItemCheck"
              @update:itemsPerPage="changePerPage"
              @update:page="changePage"
              @update:sortBy="changeSortBy"
              @update:sortDesc="changeSortDesc"
              :itemClass="itemClass"
            >
              <template v-slot:no-data>
                <TableFullScreenMessage
                  :title="$t('message.emptyTable.emailForwarders.title')"
                  :desc="$t('message.emptyTable.emailForwarders.description')"
                >
                  <template v-slot:image>
                    <hosting-email-forwarders-illustration />
                  </template>
                  <template v-slot:action>
                    <v-btn
                      :large="!$vuetify.breakpoint.smAndDown"
                      :small="$vuetify.breakpoint.smAndDown"
                      elevation="0"
                      color="primary"
                      class="p-2 ml-0 add-new-button"
                      @click="loadAddForwarderModal"
                      :loading="data[0].addButtonLoading"
                      rounded
                    >
                      <v-icon :class="$vuetify.breakpoint.mobile ? 'mr-0' : ''"
                        >$plus</v-icon
                      >
                      {{ $vuetify.breakpoint.mobile ? "" : $t('button.emailForwarder.add') }}
                    </v-btn>
                  </template>
                </TableFullScreenMessage>
              </template>
              <template v-slot:actions="item">
                <v-tooltip
                  transition="custom-tooltip"
                  open-delay="150"
                  bottom
                  z-index="99"
                  offset-overflow
                  nudge-bottom="4px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-on="on"
                      v-bind="attrs"
                      icon
                      small
                      @click="showDeleteForwarderModal(item)"
                    >
                      <v-icon>$thrash </v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t('button.emailForwarder.delete') }}</span>
                </v-tooltip>
              </template>

              <template v-slot:mobile="{ item, headers }">
                <div class="mobile-table-item__row">
                  <div
                    class="mobile-table-item__header"
                    @click="$emit('update:sortBy', headers[0])"
                  >
                    {{ $t('table.header.email') }}
                  </div>
                  <div class="mobile-table-item__value">
                    <span class="bold p-3">{{ item.email }}</span>
                  </div>
                </div>
                <div class="mobile-table-item__row">
                  <div
                    class="mobile-table-item__header"
                    @click="$emit('update:sortBy', headers[1])"
                  >
                    {{ $t('table.header.forwardTo') }}
                  </div>
                  <div class="mobile-table-item__value">
                    <span class="bold p-3">{{ item.forward_to }}</span>
                  </div>
                </div>
              </template>
              <template v-slot:updated_at="item">
                <span>{{ item.updated_at_text }}</span>
              </template>
            </hosting-table>
          </div>
        </v-col>
      </v-row>
    </v-container>
    <v-dialog
      transition="custom-dialog-transition"
      :persistent="modalOptions.persistent"
      v-model="modalOptions.open"
      ref="dialog"
    >
      <div
        class="card-overlay"
        v-if="!modalOptions.persistent"
        @click="modalOptions.open = !modalOptions.open"
      />
      <div class="card-overlay" v-else @click="$refs.dialog.animateClick()" />
      <basic-modal
        style="width: 560px"
        :modalOptions="modalOptions"
        @modal-close="modalClose"
        :key="modalRender"
        ref="modal"
      />
    </v-dialog>
  </div>
</template>

<script>
import Api from "../../apis/Api";

import BasicModal from "@/components/modal/BasicModal";
import DataIteratorFilterControls from "../../components/dataIterator/DataIteratorFilterControls.vue";
import HostingTable from "../../components/dataIterator/tables/HostingTable.vue";
import TableFullScreenMessage from "../../components/dataIterator/tables/TableFullScreenMessage.vue";
import HostingEmailForwardersIllustration from "../../components/illustrations/hosting/hosting-email-forwarders-illustration.vue";

import ActionModalMixin from "@/mixins/ActionModalMixin";
import DataIteratorPageMixin from "../../mixins/DataIteratorPageMixin";
import CustomTablePageMixin from "../../mixins/CustomTablePageMixin";
import moment from "moment";
import ActiveServerAccountSelector from "@/components/ActiveServerAccountSelector.vue";

export default {
  components: {
    ActiveServerAccountSelector,
    BasicModal,
    DataIteratorFilterControls,
    HostingTable,
    TableFullScreenMessage,
    HostingEmailForwardersIllustration,
  },
  mixins: [ActionModalMixin, DataIteratorPageMixin, CustomTablePageMixin],
  data() {
    return {
      isServerProvided: false,
      sortBy: "updated_at",
      sortDesc: false,
      searchTerm: "",
      data: [
        {
          loading: true,
          addButtonLoading: false,
          headers: [
            {
              text: this.$t('table.header.email'),
              value: "email",
              sortable: true,
            },
            {
              text: this.$t('table.header.forwardTo'),
              value: "forward_to",
              sortable: true,
            },
            {
              text: this.$t('table.header.created'),
              value: "updated_at",
              sortable: true,
            },
            {
              text: this.$t('table.header.actions'),
              value: "actions",
            },
          ],
          items: [],
        },
      ],
      page: 1,
      itemsPerPage: 8,
      modalRender: 0,
      modalOptions: { open: false },
      highlightItem: {},
      serverAccount: null
    };
  },
  props: {
    //serverAccount: Object,
    service: Object
  },
  watch: {
    highlightItem: function (newValue) {
      if (!newValue) return;
      setTimeout(() => {
        this.highlightItem = {};
      }, 1000);
    },
    service: function () {
      this.serverAccount = this.service.server_accounts[0];
    },
    serverAccount: function () {
      this.reloadData();
    },
    "modalOptions.open": function (value) {
      value
        ? this.$store.dispatch("lockBodyScroll")
        : this.$store.dispatch("unlockBodyScroll");
    },
  },
  computed: {
    itemsTotal: function () {
      return this.data[0].items.length;
    },
  },
  methods: {
    loadAddForwarderModal() {
      this.data[0].addButtonLoading = true;
      Api.cached()
        .get(`/server-accounts/${this.serverAccount.id}/email/domains`)
        .then((response) => {
          this.showAddForwarderModal(response.data.data);
        })
        .catch((error) => {
          this.$store.dispatch("addAlert", {
            success: false,
            errorMessage: Api.getErrorMessage(error),
          });
        })
        .finally(() => {
          this.data[0].addButtonLoading = false;
        });
    },
    showAddForwarderModal(domains) {
      this.resetModal();
      this.modalOptions.title = this.$t('heading.hosting.emailForwarders.modal.add.title');
      this.modalOptions.icon = "$plus";
      this.modalOptions.buttons.unshift({
        label: this.$t('button.emailForwarder.add'),
        color: "primary",
        onclick: (modal) => {
          modal.$refs.form.validate() && this.addForwarder(modal.formValues);
        },
      });
      this.modalOptions.formFields = [
        {
          label: this.$t('form.label.email'),
          type: "text-select",
          suffix: "@",
          tooltip: "tooltip.add_forwarder.email",
          textName: "email",
          selectName: "domain",
          rules: [
            (v) => /.+@.+/.test(v) ||
              this.$t('form.validation.required', {field: this.$t('form.label.email')})
          ],
          options: domains.map((item) => {
            return {
              label: item.domain,
              value: item.domain,
            };
          }),
        },
        {
          label: this.$t('form.label.destination'),
          name: "destination",
          tooltip: "tooltip.add_forwarder.destination",
          type: "text",
          rules: [
            (v) => !!v ||
              this.$t('form.validation.required', {field: this.$t('form.label.destination')}),
            (v) => /.+@.+/.test(v) ||
              this.$t('form.validation.email', {field: this.$t('form.label.destination')}),
          ],
        },
      ];
      this.modalOptions.item = { domain: domains[0].domain };
      this.modalOptions.open = true;
    },
    addForwarder(formData) {
      this.modalOptions.persistent = true;
      this.modalOptions.submitting = true;
      this.modalOptions.submittingSuccess = "";
      this.modalOptions.submittingError = "";

      Api.post(
        `/server-accounts/${this.serverAccount.id}/email/forwarders`,
        formData
      )
        .then(() => {
          this.modalOptions.submittingSuccess = this.$t('notification.hosting.emailForwarders.add.success');
          this.highlightItem = {
            email: `${formData.email}@${formData.domain}`,
            forward_to: formData.destination,
          };
          this.reloadForwarders();

          this.$store.dispatch("addAlert", {
            success: true,
            successMessage: this.modalOptions.submittingSuccess,
          });
          this.modalOptions.open = false;
        })
        .catch((error) => {
          this.modalOptions.submittingError = Api.getErrorMessage(error);

          this.$store.dispatch("addAlert", {
            success: false,
            errorMessage: this.modalOptions.submittingError,
          });
        })
        .finally(() => {
          this.modalOptions.persistent = false;
          this.modalOptions.submitting = false;
        });
    },
    showDeleteForwarderModal(item) {
      this.resetModal();
      this.modalOptions.item = item;
      this.modalOptions.title = this.$t('heading.hosting.emailForwarders.modal.delete.title');
      this.modalOptions.color = "error";
      this.modalOptions.icon = "$alertwarning";

      this.modalOptions.formFields = [
        {
          message: `<b>${this.$t('message.confirmAction')}</b>`,
          label: this.$t('form.confirmDelete.emailForwarder'),
          name: "confirm",
          type: "checkbox",
          required: true,
        },
      ];
      this.modalOptions.buttons.unshift({
        label: this.$t('button.emailForwarder.delete'),
        color: "error",
        onclick: (modal) => {
          modal.$refs.form.validate() && this.deleteForwarder(item);
        },
      });
      this.modalOptions.open = true;
    },
    deleteForwarder(item) {
      this.modalOptions.persistent = true;
      this.modalOptions.submitting = true;
      this.modalOptions.submittingSuccess = "";
      this.modalOptions.submittingError = "";

      Api.delete(
        `/server-accounts/${this.serverAccount.id}/email/forwarders/${
          item.email
        }?forward_to=${encodeURIComponent(item.forward_to)}`
      )
        .then(() => {
          this.modalOptions.submittingSuccess = this.$t('notification.hosting.emailForwarders.delete.success');
          this.reloadForwarders();

          this.$store.dispatch("addAlert", {
            success: true,
            successMessage: this.modalOptions.submittingSuccess,
          });
          this.modalOptions.open = false;
        })
        .catch((error) => {
          this.modalOptions.submittingError = Api.getErrorMessage(error);

          this.$store.dispatch("addAlert", {
            success: false,
            errorMessage: this.modalOptions.submittingError,
          });
        })
        .finally(() => {
          this.modalOptions.persistent = false;
          this.modalOptions.submitting = false;
        });
    },
    reloadForwarders() {
      this.data[0].loading = true;
      this.data[0].items = [];
      Api.get(`/server-accounts/${this.serverAccount.id}/email/forwarders`)
        .then((response) => {
          this.data[0].items = response.data.data.map((item) => {
            item.updated_at_text = item.updated_at
              ? moment(item.updated_at).fromNow()
              : "-";
            return item;
          });
        })
        .catch((error) => {
          this.$store.dispatch("addAlert", {
            success: false,
            errorMessage: Api.getErrorMessage(error),
          });
        })
        .finally(() => {
          this.data[0].loading = false;
        });
    },
    reloadData() {
      this.reloadForwarders();
    },
    itemClass(item) {
      if (
        this.highlightItem.email &&
        item.email == this.highlightItem.email &&
        item.forward_to == this.highlightItem.forward_to
      ) {
        return "highlight";
      }
    },
  },
  mounted() {
    if (this.service) {
      this.serverAccount = this.service.server_accounts[0];
      this.reloadData();
    }
  },
};
</script>

<style scoped lang="scss">
// .title-row {
//   height: 70px;
// }

.v-window::v-deep {
  // overflow: visible !important;
  background-color: transparent;

  padding: 8px 32px 16px 32px;
  margin: -8px -32px -16px -32px;
  width: calc(100% + 64px);
  max-width: unset;
  .v-window-item,
  .v-window__container {
    overflow: visible !important;
  }
}
.v-tab {
  min-width: unset;
  max-height: 46px;
}

.v-tabs::v-deep {
  max-height: 46px;
}
</style>
